import { Button, Form, Input, Modal } from 'antd'
import Card from '../../Components/Card'
import { FC } from 'react'
import './index.scss'
import { useRequest } from 'ahooks'
import { http, post } from '../../Utils/Http'
import { useHistory } from 'react-router-dom'

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const [form] = Form.useForm()

  const history = useHistory()

  const { run, loading } = useRequest(doLogin, { manual: true })

  async function doLogin(formData: any) {
    const url = http.defaults.baseURL + '/api/auth/v1/pc/login'
    const res = await post<any>(url, formData)

    if (res.code === 200) {
      sessionStorage.setItem('token', res.data.accessToken)
      history.push('/')
    } else {
      Modal.error({
        title: '登录失败',
        content: res.msg,
      })
    }
  }

  return (
    <div
      className="login-page"
      style={{ backgroundImage: `url('/images/bg.png')` }}
    >
      <div className="login-page-title">
        <img src="/images/logo.png"></img>
        煤矿安全考试系统
      </div>
      <Card width={800} height={480}>
        <Form
          form={form}
          wrapperCol={{ span: 22, offset: 1 }}
          onFinish={run}
          style={{ width: 500 }}
        >
          <Form.Item
            label="账号"
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}
            style={{ marginBottom: 30 }}
          >
            <Input placeholder="请输入用户名" size="large" />
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input
              type="password"
              placeholder="请输入密码"
              onPressEnter={form.submit}
              size="large"
            />
          </Form.Item>

          <Button
            style={{ marginTop: 20 }}
            block
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
          >
            登录
          </Button>
        </Form>
      </Card>
    </div>
  )
}

export default Login
