import { useRequest } from 'ahooks'
import { Button, Card, PageHeader, Skeleton } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Question from '../../Exam/Components/Question'
import { Ques } from '../../Exam/Model'
import { getRecordQuesList } from '../Service'

interface IState {
  quesList: Ques[]
  index: number
  current: Ques
}

const defaultState = {
  quesList: [],
  index: 0,
  current: new Ques(),
} as IState

const Index: FC = () => {
  const [state, setState] = useState(defaultState)

  const params = useParams<{ id: string }>()

  const history = useHistory()

  const { loading } = useRequest(() => getRecordQuesList(params.id), {
    onSuccess(quesList) {
      setState({
        quesList: quesList,
        index: 0,
        current: quesList[0],
      })
    },
  })

  function pre() {
    if (state.index <= 0) return
    const index = state.index - 1
    setState({
      ...state,
      index: index,
      current: state.quesList[index],
    })
  }

  function next() {
    if (state.index >= state.quesList.length - 1) return
    const index = state.index + 1
    setState({
      ...state,
      index: index,
      current: state.quesList[index],
    })
  }

  return (
    <PageHeader
      title="试卷预览"
      onBack={history.goBack}
      style={{ width: '1200px', margin: '0 auto', padding: '0' }}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <Card>
          <p>共{state.quesList.length}道题</p>
          <Question ques={state.current} index={state.index} isPreview />
          <ButtonGroup>
            <Button type="primary" disabled={state.index <= 0} onClick={pre}>
              上一题
            </Button>
            <Button
              type="primary"
              disabled={state.index >= state.quesList.length - 1}
              onClick={next}
            >
              下一题
            </Button>
          </ButtonGroup>
        </Card>
      )}
    </PageHeader>
  )
}

export default Index
