import { FC } from 'react'
import { Ques, QuesType } from '../../Model'
import { Avatar, Button, Card, Progress, Tag } from 'antd'
import './index.scss'

interface QuesTreeProps {
  quesList: Ques[]
  quesIndex: number
  onSelect: (index: number) => void
  onSubmit?: () => void
  submitShow?: boolean
}

const QuesTree: FC<QuesTreeProps> = ({ quesIndex, quesList, onSelect, onSubmit, submitShow = true }) => {
  const quesList_ = quesList.map((item, index) => ({
    ...item,
    index: index + 1,
  }))
  const SingleChoiceLists = quesList_.filter((item) => item.quesType === QuesType.SingleChoice)
  const MultiChoiceLists = quesList_.filter((item) => item.quesType === QuesType.MultiChoice)
  const TrueOrFalseLists = quesList_.filter((item) => item.quesType === QuesType.TrueOrFalse)
  const FillBlankLists = quesList_.filter((item) => item.quesType === QuesType.FillBlank)
  const AnswerLists = quesList_.filter((item) => item.quesType === QuesType.Answer)

  const answeredNumber = quesList.filter((x) => x.selfAnsw).length
  const process = (answeredNumber / quesList.length) * 100

  const renderTag = (list: Ques[], name: string) => {
    return (
      <div>
        <div className="tags-title">
          <span>{name}</span>
          <span>共{list.length}道</span>
        </div>
        <div className="tags-box">
          {list.map((item) => {
            return (
              <Tag
                key={item.id}
                style={{
                  width: 40,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 8,
                  marginRight: 12,
                }}
                color={item.selfAnsw !== '' ? '#2b3b71' : '#dde3ea'}
                onClick={() => onSelect(item.index - 1)}
              >
                <span
                  style={{
                    color: item.selfAnsw !== '' ? '#fff' : '#a9aeb8',
                  }}
                >
                  {item.index}
                </span>
              </Tag>
            )
          })}
        </div>
      </div>
    )
  }
  const avatar = localStorage.getItem('avator')
  const realname = localStorage.getItem('realname')
  const deptName = localStorage.getItem('deptName')
  return (
    <div className="questree">
      <Card style={{ width: 320, minHeight: 655 }}>
        <div className="userInfo flex">
          <Avatar src={avatar} size={64} style={{ backgroundColor: '#ccc' }} />
          <div style={{ marginLeft: 10 }}>
            <b style={{ fontSize: 16 }}>{realname}</b>
            <div style={{ marginTop: 6, fontSize: 15 }}>{deptName}</div>
          </div>
        </div>
        <div className="process-box">
          <div className="line1">
            <span>答题进度</span>
            <span>
              {answeredNumber}/{quesList.length}
            </span>
          </div>
          <Progress
            percent={process}
            showInfo={false}
            size="small"
            strokeColor="#5398f7"
            success={{ strokeColor: '#5398f7' }}
          />
        </div>
        {submitShow && (
          <Button type="primary" block onClick={onSubmit} style={{ marginBottom: '20px' }}>
            交卷
          </Button>
        )}
        {Boolean(SingleChoiceLists?.length) && renderTag(SingleChoiceLists, '单选题')}
        {Boolean(MultiChoiceLists?.length) && renderTag(MultiChoiceLists, '多选题')}
        {Boolean(TrueOrFalseLists?.length) && renderTag(TrueOrFalseLists, '判断题')}
        {Boolean(FillBlankLists?.length) && renderTag(FillBlankLists, '填空题')}
        {Boolean(AnswerLists?.length) && renderTag(AnswerLists, '简答题')}
      </Card>
    </div>
  )
}

export default QuesTree
