export const TimeDurationText = (value: number) => {
  const h = Math.floor(value / 3600)
  const m = Math.floor((value - h * 3600) / 60)
  const s = (value - h * 3600) % 60
  return {
    hours: h,
    minute: m,
    second: s,
  }
}
