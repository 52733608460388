import { Layout, Menu, Modal } from 'antd'
import {
  FileDoneOutlined,
  FileSearchOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { FC, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './MainLayout.scss'
import Avatar from 'antd/lib/avatar/avatar'
import { useRequest } from 'ahooks'
import { getUserInfo } from '../Pages/My/Service'

const { Header, Content, Footer } = Layout

const Index: FC = ({ children }) => {
  const history = useHistory()
  const [current, setCurrent] = useState('')

  const { data: user } = useRequest(getUserInfo, {
    onSuccess: (res) => {
      localStorage.setItem('avatar', res.avatar)
      localStorage.setItem('deptName', res.deptName)
      localStorage.setItem('realname', res.realname)
      localStorage.setItem('identityCard', res.identityCard)
    },
  })

  function changeMenu(e: any) {
    const key = e.key

    if (key === 'logout') {
      Modal.confirm({
        title: '提示',
        content: '您确认要退出登录吗？',
        onOk() {
          history.push('/login')
          sessionStorage.clear()
          return
        },
      })
    } else {
      setCurrent(key)
    }
  }

  return (
    <Layout className="layout">
      <Header className="header" style={{ background: '#fff' }}>
        <div className="inner">
          <div className="logo">
            <Avatar src="/logo.png" size={42} />
            <div className="title">煤矿安全考试平台</div>
          </div>

          <Menu
            selectedKeys={[current]}
            onClick={changeMenu}
            mode="horizontal"
            theme="light"
          >
            <Menu.Item key="exam-list" icon={<FileDoneOutlined />}>
              <Link to="/exam">所有考试</Link>
            </Menu.Item>

            <Menu.Item key="exam-record" icon={<FileSearchOutlined />}>
              <Link to="/record">考试记录</Link>
            </Menu.Item>

            <Menu.Item key="user-info" icon={<UserOutlined />}>
              <Link to="/my">{user?.realname}</Link>
            </Menu.Item>

            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              退出登录
            </Menu.Item>
          </Menu>
        </div>
      </Header>

      <Content className="content">{children}</Content>

      {/* <Footer className="footer">煜哲科技提供技术支持</Footer> */}
    </Layout>
  )
}

export default Index
