import { useRequest } from 'ahooks'
import { Avatar, Card, Skeleton } from 'antd'
import { FC } from 'react'
import { getUserInfo } from './Service'
import './index.scss'

const Index: FC = () => {
  const { data, loading } = useRequest(getUserInfo)

  return loading ? (
    <Skeleton avatar />
  ) : (
    <>
      <Card style={{ width: '1200px', margin: '0 auto' }}>
        <Card.Meta
          avatar={<Avatar src={data?.avatar} size={64} />}
          title={data?.realname}
          description={data?.deptName}
        />

        <div className="desc">
          <div className="row">
            <div className="key">性别</div>
            <div className="value">{data?.gender}</div>
          </div>
          <div className="row">
            <div className="key">电话</div>
            <div className="value">{data?.mobile}</div>
          </div>
          <div className="row">
            <div className="key">职务</div>
            <div className="value">{data?.duty}</div>
          </div>
          <div className="row">
            <div className="key">生日</div>
            <div className="value">{data?.birthday}</div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default Index
