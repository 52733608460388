import { Input, Tag } from 'antd'
import { FC, useEffect, useState } from 'react'
import { QuesProps } from '../../Model'

const Answer: FC<QuesProps> = ({ ques, index, isPreview }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(ques.selfAnsw)
  }, [ques])

  function onChange(e: any) {
    setValue(e.target.value)
  }

  function onBlur() {
    ques.selfAnsw = value
  }
  return (
    <div className="question-item">
      <div className="left">
        <Tag color="magenta">简答题</Tag>
      </div>
      <div className="right">
        <div className="ques-title">
          <div className="index">{index + 1}</div>
          <div className="title">{ques.info}</div>
        </div>
        <div className="ques-options">
          <Input.TextArea
            rows={5}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isPreview}
          />
        </div>
      </div>
    </div>
  )
}

export default Answer
