import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import reportWebVitals from './reportWebVitals'
import Login from './Pages/Login'
import MainLayout from './Layout/MainLayout'
import Record from './Pages/Record'
import My from './Pages/My'

import Exam from './Pages/Exam'
import ExamList from './Pages/Exam/ExamList'
import DoExam from './Pages/Exam/DoExam'
import ExamResult from './Pages/Exam/ExamResult'

import Preview from './Pages/Record/Preview'
import AnswerCateList from './Pages/Exam/DoAnswer/AnswerCateList'
import DoAnswer from './Pages/Exam/DoAnswer/DoAnswer'

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <Switch>
          <Route path="/exam/do-exam/:id" component={DoExam} />
          <Route path="/login" component={Login} />
          <MainLayout>
            <Route path="/exam" component={Exam} exact />
            <Route path="/my" component={My} exact />

            <Route path="/record" component={Record} exact />
            <Route path="/record/preview/:id" component={Preview} />

            <Route path="/exam/list/:id" component={ExamList} />
            <Route path="/exam/result/:id" component={ExamResult} />

            <Route
              path="/exam/const/do-answer/:id"
              component={DoAnswer}
              exact
            />
            <Route path="/exam/const/:id" component={AnswerCateList} exact />

            <Route path="/" component={Exam} exact />
          </MainLayout>
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
reportWebVitals()
