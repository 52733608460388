import axios, { AxiosError } from 'axios'
import { Exception, HttpRes } from '../Models/Common'
import { Modal } from 'antd'

let baseURL = 'https://trainpcapi.yuzhekeji.club'
// zzmk
// let baseURL = 'http://192.168.10.220:8080'
// ymek
// let baseURL = 'http://192.168.254.72:8081'
if (process.env.NODE_ENV === 'development') {
  baseURL = 'https://trainpcapi.yuzhekeji.club'
}

export const http = axios.create({
  baseURL: baseURL,
  timeout: 10000,
})

http.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token')
  config.headers || (config.headers = {})
  config.headers['Authorization'] = 'Bearer ' + token
  config.headers['Content-Type'] = 'application/json'
  return config
})

http.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const response = error.response!

    if (!response) {
      throw new Exception(500, '请求出错')
    }

    if (response.status === 401) {
      window.location.href = '/login'
    }

    Modal.error({
      title: '请求出错',
      content: response.statusText,
    })

    throw new Exception(response.status, response.statusText)
  }
)

export async function post<T>(url: string, data?: any): Promise<HttpRes<T>> {
  const res = await http.post(url, data)
  const httpRes = res.data as HttpRes<T>
  if (httpRes.code === 401) {
    window.location.href = '/login'
    throw new Exception(httpRes.code, httpRes.msg)
  }
  if (httpRes.code !== 200) {
    Modal.error({
      title: '请求出错',
      content: httpRes.msg,
    })
    throw new Exception(httpRes.code, httpRes.msg)
  }
  return httpRes
}
