export enum QuesResultType {
  Correct = 1,
  Error = 2,
  Nuable = 3,
}

export enum QuesType {
  SingleChoice = 1,
  MultiChoice = 2,
  TrueOrFalse = 3,
  FillBlank = 4,
  Answer = 5,
}

export enum TimeValidity {
  True = 1,
  False = 2,
}

export class Exam {
  id = ''
  name = ''
  startTime = ''
  endTime = ''
  timeValidity = TimeValidity.True
}

export class ExamCate {
  id = ''
  name = ''
  parentId? = ''
  hasChildren? = false
}

export interface CanExamResult {
  isJoinExam: boolean
  errMsg: string
  isPass: boolean
}

export class IsPassResult {
  score = 0
  pass = false
  examNum = 0
}

export class ExamInfo {
  examScoreId = ''
  seconds = 0
  quesList = [] as Ques[]
}

export class Ques {
  id = ''
  quesId = ''
  quesType = QuesType.SingleChoice
  info = ''
  blackCount = 0
  rightAnsw = ''
  selfAnsw = ''
  score = 0
  resultType = QuesResultType.Nuable
  showTips = false
  optionList = [] as QuesOption[]
  fillAnswerList = []
  index = 0
  confirm = false
}

export class AnswerQues extends Ques {
  confirm = false
}

export class QuesOption {
  key = ''
  option = ''
  checked = false
}

export interface QuesProps {
  ques: Ques
  index: number
  isPreview?: boolean
}

export interface ExamResult {
  examName: string
  submitPaperTime: string
  isPass: boolean
  score: number
  quesErrorCount: number
  quesRightCount: number
  quesEmptyCount: number
  quesTotalCount: number
  useTime: number
}
