import { Button, Card } from 'antd'
import { FC } from 'react'
import { Exam } from '../../Model'
import './index.scss'
import { ScheduleOutlined } from '@ant-design/icons'

interface ExamItemProps {
  exam: Exam
  onClick?: () => void
}

const ExamItem: FC<ExamItemProps> = ({ exam, onClick }) => {
  return (
    <Card className="exam-item" onClick={onClick} title={exam.name} hoverable={true} style={{ width: 380 }}>
      <div className="line">
        <span>
          <ScheduleOutlined style={{ marginRight: 5 }} />
          {exam.startTime ?? '不限时'}
        </span>
        <span></span>
        <span>
          <ScheduleOutlined style={{ marginRight: 5 }} />
          {exam.endTime ?? '不限时'}
        </span>
      </div>

      <Button type="primary" style={{ marginTop: 50 }}>
        开始考试
      </Button>
    </Card>
  )
}

export default ExamItem
