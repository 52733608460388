import { useRequest } from 'ahooks'
import { Button, Card, Skeleton, Space, PageHeader } from 'antd'
import { clearQuesCache, getConstCateList } from './Service'
import { useHistory, useParams } from 'react-router-dom'
import { ArrowRightOutlined } from '@ant-design/icons'

const AnswerCateList = () => {
  const history = useHistory()

  const params = useParams<{ id: string }>()

  const { loading, data } = useRequest(() => getConstCateList(params.id), {
    refreshDeps: [params.id],
  })

  return (
    <PageHeader
      title={
        <>
          <Space size={[40, 0]}>
            <div>题库练习分类</div>
            <Button onClick={() => clearQuesCache()}>清除答题缓存</Button>
          </Space>
        </>
      }
      onBack={() => history.goBack()}
      style={{ width: '1200px', margin: '0 auto', padding: '0' }}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <div className="exam-list">
          {data?.map((item: any) => (
            <Card style={{ width: '100%' }} key={item.id}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{item.name}</div>
                {item.hasChildren ? (
                  <ArrowRightOutlined
                    onClick={() => history.push(`/exam/const/${item.id}`)}
                  />
                ) : (
                  <Button
                    type="primary"
                    onClick={() =>
                      history.push(`/exam/const/do-answer/${item.id}`)
                    }
                  >
                    开始答题
                  </Button>
                )}
              </div>
            </Card>
          ))}
        </div>
      )}
    </PageHeader>
  )
}

export default AnswerCateList
