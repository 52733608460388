export enum ScoreType {
  Latest = 1,
  Heighest = 2,
}

export class ExamRecord {
  examId = ''
  examName = ''
  isPass = false
  score = 0
  scoreType = ScoreType.Latest
  scoreList = [] as ScoreItem[]
}

export class ScoreItem {
  createTime = ''
  examScoreId = ''
  isPass = false
  score = 0
}
