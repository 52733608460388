import { useAntdTable } from 'ahooks'
import { Radio, RadioChangeEvent, Table, TableColumnType, Tag } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import { FC, useEffect, useState } from 'react'
import { ExamCate } from '../Exam/Model'
import { getExamCateList } from '../Exam/Service'
import { ExamRecord, ScoreType } from './Model'
import { getRecordList } from './Service'
interface IState {
  cateList: ExamCate[]
  current: string
}

const defaultState = { cateList: [], current: '' } as IState

const Index: FC = () => {
  const tableColumns: TableColumnType<ExamRecord>[] = [
    { key: 'examId', title: 'ID', dataIndex: 'examId', width: 220 },
    { key: 'examName', title: '考试名称', dataIndex: 'examName' },
    {
      key: 'scoreType',
      title: '最新/最高 成绩',
      dataIndex: 'scoreType',
      render: (val) =>
        val === ScoreType.Heighest ? (
          <Tag color="gold">最高</Tag>
        ) : (
          <Tag color="success">最新</Tag>
        ),
    },
    { key: 'score', title: '分数', dataIndex: 'score' },
    {
      key: 'isPass',
      title: '是否合格',
      dataIndex: 'isPass',
      render: (val) =>
        val ? <Tag color="success">合格</Tag> : <Tag color="red">不合格</Tag>,
    },
  ]

  const cacheId = 'record_exam_cate_id'

  const [state, setState] = useState(defaultState)

  const { tableProps } = useAntdTable(loadRecordList, {
    refreshDeps: [state.current],
    ready: !!state.current,
    defaultPageSize: 10,
  })

  useEffect(() => {
    getExamCateList().then((data) => {
      let id = sessionStorage.getItem(cacheId)
      if (!id) {
        id = data[0].id
      }
      setState({ cateList: data, current: id })
    })
  }, [])

  function cateChange(e: RadioChangeEvent) {
    const id = e.target.value
    setState({
      cateList: state.cateList,
      current: e.target.value,
    })
    sessionStorage.setItem(cacheId, id)
  }

  async function loadRecordList(page: PaginationConfig) {
    const res = await getRecordList(
      state.current,
      page.current!,
      page.pageSize!
    )
    return {
      total: res.data.totalCount,
      list: res.data.list,
    }
  }

  return (
    <div className="record-page" style={{ width: '1200px', margin: '0 auto' }}>
      <Radio.Group
        value={state.current}
        onChange={cateChange}
        buttonStyle="solid"
      >
        {state.cateList.map((cate) => (
          <Radio.Button value={cate.id} key={cate.id}>
            {cate.name}
          </Radio.Button>
        ))}
      </Radio.Group>

      <Table
        {...tableProps}
        columns={tableColumns}
        // expandedRowRender={expandedRowRender}
        rowKey="examId"
        size="small"
        bordered
        style={{ marginTop: '20px' }}
      />
    </div>
  )
}

export default Index
