import { useRequest } from 'ahooks'
import { Button, Card, Modal, Spin } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { FC, useState } from 'react'
import { Prompt, useHistory, useParams } from 'react-router-dom'
import Question from '../Components/Question'
import { Ques } from '../Model'
import CountDown from './CountDown'
import { getQuesList, submitAnswer, submitPaper } from '../Service'
import QuesTree from '../Components/QuesTree'
import './index.scss'
import { ClockCircleOutlined } from '@ant-design/icons'

interface IState {
  quesList: Ques[]
  ques: Ques
  index: number
  seconds: number
  examScoreId: string
}

const defaultState = {
  quesList: [] as Ques[],
  ques: new Ques(),
  index: 0,
  seconds: 0,
  examScoreId: '',
} as IState

const Index: FC = () => {
  const history = useHistory()

  const params = useParams<{ id: string }>()

  const [state, setState] = useState<IState>(defaultState)

  const [loading, setLoading] = useState(false)

  useRequest(() => getQuesList(params.id), {
    onBefore: () => {
      setLoading(true)
    },
    onFinally: () => {
      setLoading(false)
    },
    onSuccess(data) {
      setState({
        quesList: data.quesList,
        ques: data.quesList[0],
        index: 0,
        seconds: data.seconds,
        examScoreId: data.examScoreId,
      })
    },
  })

  async function submitCurrentQues() {
    state.ques.confirm = true
    setLoading(true)
    setState({
      ...state,
      ques: {
        ...state.ques,
        confirm: true,
      },
    })
    await submitAnswer(state.ques.id, state.ques.id, state.ques.selfAnsw)
    setLoading(false)
  }

  async function move(index: number) {
    state.index = index
    setState({
      ...state,
      ques: state.quesList[state.index],
    })
    document.getElementsByClassName('do-exam-page-wrap')[0].scrollTop = 0
  }

  async function pre() {
    if (state.index <= 0) {
      Modal.warning({ title: '提示', content: '这是第一道题' })
      return
    }

    state.index = state.index - 1
    setState({
      ...state,
      ques: state.quesList[state.index],
    })
  }

  async function submitAndNext() {
    await submitCurrentQues()
    if (state.index >= state.quesList.length - 1) {
      return
    }

    state.index = state.index + 1
    setState({
      ...state,
      ques: state.quesList[state.index],
    })
  }

  async function forceSubmit() {
    await submitPaper(state.examScoreId)
    history.replace(`/exam/result/${state.examScoreId}`)
  }

  async function submit() {
    const list = state.quesList.filter((x) => !x.selfAnsw)
    let msg = '您确认要交卷吗？'
    if (list.length > 0) {
      msg = `还有${list.length}道题未完成，` + msg
    }

    return new Promise((resolve) => {
      Modal.confirm({
        title: '提示',
        content: msg,
        async onOk() {
          await submitPaper(state.examScoreId)
          resolve(true)
          history.replace(`/exam/result/${state.examScoreId}`)
        },
        onCancel() {
          resolve(false)
        },
      })
    })
  }

  const btnPre = (
    <Button type="primary" disabled={state.index < 1} onClick={pre}>
      上一题
    </Button>
  )
  const btnNext = (
    <Button
      type="primary"
      disabled={state.index >= state.quesList.length}
      onClick={() => {
        submitAndNext()
      }}
    >
      {state.index >= state.quesList.length - 1 ? '确认' : '确认&下一题'}
    </Button>
  )

  return (
    <div className="do-exam-page-wrap">
      <Prompt
        message={(location, action) => {
          if (action === 'POP') {
            return '您还未完成考试，确认退出吗？'
          }
          return true
        }}
      ></Prompt>
      <Spin spinning={loading}>
        <div className="do-exam-page">
          <div className="left">
            <div className="do-exam-page-title">
              <div className="time-box">
                <ClockCircleOutlined style={{ color: 'orange', marginRight: 6 }} />
                <CountDown seconds={state.seconds} onStop={forceSubmit} />
              </div>
            </div>
            <Card style={{ width: 800, height: 600 }}>
              <Question {...state} isPreview={false} />
              <ButtonGroup style={{ margin: '20px' }}>
                {btnPre}
                {btnNext}
              </ButtonGroup>
            </Card>
          </div>
          <div className="right">
            <QuesTree quesIndex={state.index} quesList={state.quesList} onSelect={(i) => move(i)} onSubmit={submit} />
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default Index
