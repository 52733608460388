import { Checkbox, Space, Tag } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FC, useEffect, useState } from 'react'
import { QuesProps } from '../../Model'
import './Question.scss'

const MultiChoice: FC<QuesProps> = ({ ques, index, isPreview }) => {
  const [key, setKey] = useState<string[]>([])

  function onChange(e: CheckboxChangeEvent) {
    const { checked, value } = e.target

    if (checked) {
      key.push(value)
    } else {
      const index = key.indexOf(value)
      key.splice(index, 1)
    }

    setKey([...key.sort()])
    ques.selfAnsw = key.sort().join('')
  }

  useEffect(() => {
    setKey(ques.selfAnsw?.split('') || [])
  }, [ques])

  return (
    <div className="question-item">
      <div className="left">
        <Tag color="geekblue">多选题</Tag>
      </div>
      <div className="right">
        <div className="ques-title">
          <div className="index">{index + 1}</div>
          <div className="title">{ques.info}</div>
        </div>
        <div className="ques-options">
          <Space direction="vertical">
            {ques.optionList.map((option) => (
              <Checkbox
                onChange={onChange}
                value={option.key}
                key={option.key}
                checked={key.indexOf(option.key) > -1}
                disabled={isPreview}
              >
                {option.key}. {option.option}
              </Checkbox>
            ))}
          </Space>
        </div>
      </div>
    </div>
  )
}

export default MultiChoice
