import { Tag } from 'antd'
import { FC } from 'react'
import { QuesProps, QuesResultType, QuesType } from '../../Model'
import Answer from './Answer'
import FillBlank from './FillBlank'
import MultiChoice from './MultiChoice'
import SingleChoice from './SingleChoice'
import TrueOrFalse from './TrueOrFalse'
import './Question.scss'

const Question: FC<QuesProps> = (props) => {
  function render() {
    const type = props.ques.quesType
    if (type === QuesType.SingleChoice) {
      return <SingleChoice {...props} />
    }

    if (type === QuesType.MultiChoice) {
      return <MultiChoice {...props} />
    }

    if (type === QuesType.TrueOrFalse) {
      return <TrueOrFalse {...props} />
    }

    if (type === QuesType.FillBlank) {
      return <FillBlank {...props} />
    }

    if (type === QuesType.Answer) {
      return <Answer {...props} />
    }

    return <h1>{type}</h1>
  }

  function renderPreview() {
    let correct = false
    if (props.isPreview) {
      correct = props.ques.selfAnsw === props.ques.rightAnsw
    } else {
      correct = props.ques.resultType === QuesResultType.Correct
    }
    return (
      <div className="ques-preview">
        <Tag color={correct ? 'success' : 'error'}>
          {correct ? '回答正确' : '回答错误'}
        </Tag>
        <span>您的答案：{props.ques.selfAnsw}</span>
        <div className="right-answ">正确答案: {props.ques.rightAnsw}</div>
      </div>
    )
  }

  return (
    <div className="ques-container">
      <div className="ques-body">{render()}</div>
      {props.isPreview ? renderPreview() : null}
    </div>
  )
}

export default Question
