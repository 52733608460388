import { useRequest } from 'ahooks'
import { Button, Divider } from 'antd'
import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getExamResult } from '../Service'
import { TimeDurationText } from '../../../Utils/utils'
import './index.scss'

const Index: FC = () => {
  const params = useParams<{ id: string }>()

  const history = useHistory()

  const { data } = useRequest(() => getExamResult(params.id))
  const { hours, minute, second } = TimeDurationText(data?.useTime || 0)
  return (
    <div className="result-page">
      <div
        className={`result-card ${data?.isPass ? 'success-card' : 'fail-card'}`}
      >
        <div className="top">
          <div className="sroce-box">
            <div className="left">
              <span>{data?.score}</span>
              <span>分</span>
            </div>
            <div
              className="right"
              style={{ backgroundImage: `url('/images/jige-bg.png')` }}
            >
              <span>{data?.isPass ? '您及格了' : '您未及格'}</span>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="examName">{data?.examName}</div>
          <div className="submitPaperTime">
            <img src="/images/time.png" />
            <span>
              共计{hours}时{minute}分{second}秒
            </span>
            <img src="/images/time.png" style={{ marginLeft: 50 }} />
            {data?.submitPaperTime}交卷
          </div>
          <Divider dashed />
          <div className="list">
            <div className="item">
              <div>
                <span>{data?.quesRightCount}</span>
                <span>题</span>
              </div>
              <div>
                <span className="round round-right">您答对了</span>
              </div>
            </div>
            <Divider dashed type="vertical" />
            <div className="item">
              <div>
                <span>{data?.quesErrorCount}</span>
                <span>题</span>
              </div>
              <div>
                <span className="round round-error">您答错了</span>
              </div>
            </div>
            <Divider dashed type="vertical" />
            <div className="item">
              <div>
                <span>{data?.quesEmptyCount}</span>
                <span>题</span>
              </div>
              <div>
                <span className="round round-empty">您未作答</span>
              </div>
            </div>
          </div>

          <div className="total">
            本次答题共计{data?.quesTotalCount}题，继续努力哦~
          </div>

          <div
            style={{
              marginTop: 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button type="primary" onClick={history.goBack}>
              返回考试列表
            </Button>
          </div>
        </div>
        <img src="/images/book.png" className="book-img" />
      </div>
    </div>
  )
}

export default Index
