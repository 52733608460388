import { useRequest } from 'ahooks'
import { Empty, PageHeader, Skeleton } from 'antd'
import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ExamItem from '../Components/ExamItem'
import { Exam } from '../Model'
import { getExamList, isCanBeExam } from '../Service'
import './index.scss'

const Index: FC = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()

  const { data, loading } = useRequest(() => getExamList(params.id))

  async function gotoExam(exam: Exam) {
    const result = await isCanBeExam(exam.id)
    result && history.push(`/exam/do-exam/${exam.id}`)
  }

  return (
    <PageHeader
      title="考试列表"
      onBack={() => history.goBack()}
      style={{
        width: 1200,
        margin: '0 auto',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '0',
      }}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <div className="exam-list">
          {data?.map((item) => (
            <ExamItem
              exam={item}
              key={item.id}
              onClick={() => gotoExam(item)}
            />
          ))}

          {data?.length === 0 && (
            <div style={{ textAlign: 'center', width: '100%', margin: '40px' }}>
              <Empty />
            </div>
          )}
        </div>
      )}
    </PageHeader>
  )
}

export default Index
