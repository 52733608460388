import { Modal } from 'antd'
import { post } from '../../Utils/Http'
import {
  CanExamResult,
  Exam,
  ExamCate,
  ExamInfo,
  ExamResult,
  Ques,
} from './Model'

export async function getExamCateList() {
  var res = await post<{ children: ExamCate[] }>('/api/exam/v1/cate/list')
  return res.data.children
}

export async function getExamList(cateId: string) {
  var res = await post<{ list: Exam[] }>(`/api/exam/v1/exam/list/${cateId}`)
  return res.data.list
}

export async function getQuesList(examId: string) {
  const res = await post<any>(`/api/exam/v1/exam/queslist/${examId}`)

  return {
    examScoreId: res.data['examScoreId'],
    seconds: Number.parseInt(res.data['timeRemaining']),
    quesList: res.data['quesList'].sort(function (a: Ques, b: Ques) {
      return a.quesType - b.quesType
    }),
  } as ExamInfo
}

export async function submitAnswer(
  id: string,
  quesId: string,
  selfAnsw: string
) {
  return await post('/api/exam/v1/exam/saveexamquesrecord', {
    id,
    quesId,
    selfAnsw,
  })
}

export async function submitPaper(examScoreId: string) {
  await post<any>(`/api/exam/v1/exam/submitpaper/${examScoreId}`)
}

export async function getExamResult(examScoreId: string) {
  var res = await post<ExamResult>(
    `/api/exam/v1/exam/userscoreinfo/${examScoreId}`
  )
  return res.data
}

export async function isCanBeExam(examId: string): Promise<boolean> {
  const res = await post<CanExamResult>(
    `/api/exam/v1/exam/checkjoinexam/${examId}`
  )

  if (!res.data.isJoinExam) {
    Modal.error({
      title: '无法考试',
      content: res.data.errMsg,
    })
    return false
  }

  if (res.data.isPass) {
    return new Promise((resolve) => {
      Modal.confirm({
        title: '确认考试',
        content: '这场考试已合格，您要重新考试吗？',
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      })
    })
  } else {
    return true
  }
}
