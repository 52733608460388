import { Page } from '../../Models/Common'
import { post } from '../../Utils/Http'
import { Ques } from '../Exam/Model'
import { ExamRecord, ScoreItem } from './Model'

export async function getRecordList(
  cateId: string,
  page: number,
  limit: number
) {
  return await post<Page<ExamRecord>>('/api/exam/v1/exam/record/page', {
    page,
    limit,
    cateId,
  })
}

export async function getRecordQuesList(examScoreId: string) {
  var res = await post<any>(`/api/exam/v1/exam/userscoreinfo/${examScoreId}`)
  return res.data.quesList as Ques[]
}

export async function getScoreList(examId: string) {
  var res = await post<{ list: ScoreItem[] }>(
    `/api/exam/v1/exam/score/list/${examId}`
  )
  return res.data.list
}
