import { useRequest } from 'ahooks'
import { Button, Card, Skeleton, PageHeader, Spin } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Question from '../Components/Question'
import { AnswerQues } from '../Model'
import { getConstQuesList, saveQuesCache } from './Service'
import QuesTree from '../Components/QuesTree'
import './index.scss'

interface IState {
  quesList: AnswerQues[]
  ques: AnswerQues
  index: number
}

const DoAnswer = () => {
  const history = useHistory()

  const params = useParams<{ id: string }>()

  const [state, setState] = useState<IState>({
    quesList: [] as AnswerQues[],
    ques: new AnswerQues(),
    index: 0,
  })

  const { loading } = useRequest(() => getConstQuesList(params.id), {
    refreshDeps: [params.id],
    onSuccess: (quesList) => {
      const answedIndex = quesList.findIndex((x) => !x.confirm)
      setState({
        quesList: quesList,
        ques: quesList.length > 0 ? quesList[0] : new AnswerQues(),
        index: answedIndex > -1 ? answedIndex : 0,
      })
    },
  })

  function pre() {
    if (state.index <= 0) return

    state.index = state.index - 1
    setState({
      ...state,
      ques: state.quesList[state.index],
    })
  }

  function next() {
    if (state.index >= state.quesList.length - 1) return

    state.index = state.index + 1
    setState({
      ...state,
      ques: state.quesList[state.index],
    })
  }

  async function move(index: number) {
    state.index = index
    setState({
      ...state,
      ques: state.quesList[state.index],
    })
    document.getElementsByClassName('do-answer-page-wrap')[0].scrollTop = 0
  }

  function confirm() {
    state.ques.confirm = true
    setState({
      ...state,
    })
  }

  const btnPre = (
    <Button disabled={state.index < 1} onClick={pre}>
      上一题
    </Button>
  )
  const btnNext = (
    <Button disabled={state.index >= state.quesList.length - 1} onClick={next}>
      下一题
    </Button>
  )
  const btnConfirm = (
    <Button type="primary" disabled={state.ques.confirm} onClick={confirm}>
      确认
    </Button>
  )

  return (
    <PageHeader
      title="题库练习答题"
      onBack={() => {
        saveQuesCache(params.id, state.quesList)
        history.goBack()
      }}
      style={{
        width: 1200,
        margin: '0 auto',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '0',
      }}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <div className="do-answer-page-wrap">
          <div className="do-answer-page">
            <div className="left">
              <Card style={{ width: 800, height: 600 }}>
                <Question {...state} isPreview={state.ques.confirm} />
                <ButtonGroup style={{ margin: '20px' }}>
                  {btnPre}
                  {state.ques.confirm === undefined ? btnConfirm : btnNext}
                </ButtonGroup>
              </Card>
            </div>
            <div className="right">
              <QuesTree
                quesIndex={state.index}
                quesList={state.quesList}
                onSelect={(i) => move(i)}
                submitShow={false}
              />
            </div>
          </div>
        </div>
      )}
    </PageHeader>
  )
}

export default DoAnswer
