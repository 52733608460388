import { Card, Image } from 'antd'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { ExamCate } from '../../Model'
import './index.scss'

interface ExamCateItemProps {
  examCate: ExamCate
  index: number
}

const ExamCateItem: FC<ExamCateItemProps> = ({ examCate, index }) => {
  const subTitleList = [
    '如切如磋，如琢如磨',
    '考试是检验才华的标尺',
    '不问收获，但问耕耘',
    '把握今天的奋斗',
    '苦海有涯，而学无涯',
    '名题金榜',
    '迎接新挑战，创造新成绩',
    '不问收获，但问耕耘',
    '如切如磋，如琢如磨',
    '考试是检验才华的标尺',
    '不问收获，但问耕耘',
    '把握今天的奋斗',
    '苦海有涯，而学无涯',
    '名题金榜',
    '迎接新挑战，创造新成绩',
  ]

  function getImage() {
    if (examCate.name.indexOf('正式') > -1) return '/images/exam_official.png'
    if (examCate.name.indexOf('模拟') > -1) return '/images/exam_simulate.png'
    if (examCate.name.indexOf('违') > -1) return '/images/exam_break.png'
    if (examCate.name.indexOf('安全') > -1) return '/images/exam_safe.png'
    if (examCate.name.indexOf('月') > -1) return '/images/exam_month.png'
    if (examCate.id === 'const') return '/images/test_exercise.png'
    return '/images/exam_common.png'
  }

  const history = useHistory()

  function gotoExamList() {
    if (examCate.id === 'const') {
      history.push('/exam/const/0')
    } else {
      history.push(`/exam/list/${examCate.id}`)
    }
  }

  return (
    <Card className="exam-cate-card" onClick={() => gotoExamList()}>
      <div className="exam-cate-item">
        <div className="image">
          <Image src={getImage()} height={48} width={48} preview={false} />
        </div>
        <div className="info">
          <div className="title">{examCate.name}</div>
          <div className="sub-title">{subTitleList[index]}</div>
        </div>
      </div>
    </Card>
  )
}

export default ExamCateItem
