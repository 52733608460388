import { useRequest } from 'ahooks'
import { Skeleton } from 'antd'
import { FC } from 'react'
import ExamCateItem from './Components/ExamCateItem'
import { getExamCateList } from './Service'

const Index: FC = () => {
  const { data, loading } = useRequest(getExamCateList)

  return loading ? (
    <Skeleton />
  ) : (
    <div style={{ width: 1200, margin: '0 auto' }}>
      {data?.map((item, index) => (
        <ExamCateItem examCate={item} index={index} key={item.id} />
      ))}

      <ExamCateItem
        examCate={{ id: 'const', name: '题库练习' }}
        index={data?.length ?? 0}
        key="const"
      />
    </div>
  )
}

export default Index
