import useCountdown from 'ahooks/lib/useCountDown'
import { FC, useEffect, useState } from 'react'

interface CountDownProps {
  seconds: number
  onStop: () => void
}

interface IState {
  time: number
  started: boolean
}

const CountDown: FC<CountDownProps> = ({ seconds, onStop }) => {
  const [state, setState] = useState<IState>({
    time: 0,
    started: false,
  })

  const [, formatted] = useCountdown({
    targetDate: state.time,
    onEnd: () => state.started && onStop(),
  })

  useEffect(() => {
    if (seconds > 0) {
      setState({
        time: Date.now() + seconds * 1000,
        started: true,
      })
    }
  }, [seconds])

  return (
    <>
      {formatted.hours * 60 + formatted.minutes}分{formatted.seconds}秒
    </>
  )
}

export default CountDown
