import { Radio, RadioChangeEvent, Space, Tag } from 'antd'
import { FC, useEffect, useState } from 'react'
import { QuesProps } from '../../Model'

const TrueOrFalse: FC<QuesProps> = ({ ques, index, isPreview }) => {
  const [key, setKey] = useState('')

  function onChange(e: RadioChangeEvent) {
    const value = e.target.value || ''
    ques.selfAnsw = value
    setKey(value)
  }

  useEffect(() => {
    setKey(ques.selfAnsw)
  }, [ques])

  return (
    <div className="question-item">
      <div className="left">
        <Tag color="purple">判断题</Tag>
      </div>
      <div className="right">
        <div className="ques-title">
          <div className="index">{index + 1}</div>
          <div className="title">{ques.info}</div>
        </div>
        <div className="ques-options">
          <Radio.Group value={key} onChange={onChange} disabled={isPreview}>
            <Space direction="vertical">
              {ques.optionList.map((option) => (
                <Radio value={option.key} key={option.key}>
                  {option.key}. {option.option}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      </div>
    </div>
  )
}

export default TrueOrFalse
