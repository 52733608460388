export type HttpRes<T> = {
  code: number
  data: T
  msg: string
}

export type Page<T> = {
  totalCount: number
  list: T[]
}

export class Exception {
  constructor(code: number, message: string) {
    this.message = message
    this.code = code
  }

  code: number = 0
  message: string = ''
}