import { post } from '../../../Utils/Http'
import { AnswerQues, ExamCate } from '../Model'

const answerQuesKey = 'const-answer-ques-'

export async function getConstCateList(
  parentId: string = '0'
): Promise<ExamCate[]> {
  const res = await post<any>('/api/ques/v1/cate/list')
  const cateList = (res.data.list ?? []) as ExamCate[]
  const result = cateList.filter((x) => x.parentId === parentId)
  result.forEach((item) => {
    item.hasChildren = cateList.some((x) => x.parentId === item.id)
  })
  return result
}

export async function getConstQuesList(cateId: string): Promise<AnswerQues[]> {
  const cache = localStorage.getItem(answerQuesKey + cateId)
  if (cache) {
    return JSON.parse(cache)
  }

  const res = await post<any>(`/api/ques/v1/ques/bank/exercise/list/${cateId}`)
  const result = (res.data.list ?? []) as any
  result.forEach((item: any) => {
    item.rightAnsw = item['answer']
    item.info = item['quesInfo']
  })
  return result as AnswerQues[]
}

export async function saveQuesCache(cateId: string, quesList: AnswerQues[]) {
  localStorage.setItem(answerQuesKey + cateId, JSON.stringify(quesList))
}

export async function clearQuesCache() {
  const keys = Object.getOwnPropertyNames(localStorage)
  for (const i in keys) {
    const key = keys[i]
    if (key.includes(answerQuesKey)) {
      localStorage.removeItem(key)
    }
  }
}
