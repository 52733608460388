import './index.scss'
interface Iprops {
  children: any
  width?: number
  height?: number
}
const Card = (props: Iprops) => {
  const { width, height } = props
  return (
    <div
      className="card-box"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        minHeight: `${height}px`,
      }}
    >
      <div
        className="title-box"
        style={{ backgroundImage: `url('/images/title-bg.png')` }}
      >
        <div className="title">矿校云</div>
      </div>
      {props.children}
    </div>
  )
}
export default Card
