import { Input, Space, Tag } from 'antd'
import { FC, useEffect, useState } from 'react'
import { QuesProps } from '../../Model'

const FillBlank: FC<QuesProps> = ({ ques, index, isPreview }) => {
  const [values, setValues] = useState<string[]>([])

  useEffect(() => {
    if (!ques.selfAnsw) {
      const arr = []
      for (let index = 0; index < ques.fillAnswerList.length; index++) {
        arr.push('')
      }
      setValues(arr)
    } else {
      var json = JSON.parse(ques.selfAnsw)
      setValues(json)
    }
  }, [ques])

  function onChange(e: any, index: number) {
    values[index] = e.target.value
    setValues([...values])
  }

  function onBlur() {
    ques.selfAnsw = JSON.stringify(values)
  }

  return (
    <div className="question-item">
      <div className="left">
        <Tag color="purple">填空题</Tag>
      </div>
      <div className="right">
        <div className="ques-title">
          <div className="index">{index + 1}</div>
          <div className="title">{ques.info}</div>
        </div>

        <div className="ques-options">
          <Space direction="vertical">
            {values.map((item, index) => (
              <Input
                key={index}
                value={item}
                onChange={(e) => onChange(e, index)}
                onBlur={onBlur}
                disabled={isPreview}
              />
            ))}
          </Space>
        </div>
      </div>
    </div>
  )
}

export default FillBlank
